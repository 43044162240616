<table>
  <tr>
    <th>Models</th>
    <th></th>
    <th>Overlapping activity</th>
  </tr>
  <tr *ngFor="let overlap of displayOverlaps">
    <td>
      <div *ngFor="let modelIdTitle of overlap.modelIdTitles">
        <div *ngIf="overlap.enabledByGene">
          <a routerLink="/pombase_gocam_view/gene/{{modelIdTitle[0]}}/{{overlap.enabledByGene.uniquename}}/{{overlap.enabledByGene.displayName()}}">
            {{modelIdTitle[0]}} - {{modelIdTitle[1]}}
          </a>
        </div>
      </div>
    </td>
    <td>
      <div class="merged-link" *ngIf="overlap.enabledByGene">
        <a routerLink="/pombase_gocam_view/docs/{{overlap.mergedIds}}/{{overlap.enabledByGene.uniquename}}/{{overlap.enabledByGene.displayName()}}">
          View merged model
        </a>
      </div>
    </td>
    <td>
      <div>
        <a *ngIf="overlap.node_id.startsWith('GO:')" routerLink="/term/{{overlap.node_id}}">
          {{overlap.node_label}}
        </a>
        enabled by
        <app-gene-link *ngIf="overlap.enabledByGene" [long]="false" [gene]="overlap.enabledByGene"></app-gene-link>
      </div>
      <div *ngIf="overlap.occursInComponent">
        <div *ngIf="overlap.occursInComponent.id.startsWith('GO:')">
          occurs in: <a routerLink="/term/{{overlap.occursInComponent.id}}">{{overlap.occursInComponent.label}}</a>
        </div>
        <div *ngIf="!overlap.occursInComponent.id.startsWith('GO:')">
          occurs in: {{overlap.occursInComponent.label}} ({{overlap.occursInComponent.id}})
        </div>
      </div>

      <div *ngIf="overlap.part_of_process">
        part of: <a routerLink="/term/{{overlap.part_of_process.id}}">{{overlap.part_of_process.label}}</a>
      </div>
    </td>
  </tr>
</table>

