<table *ngIf="gocams.length > 0">
  <tr>
    <th>Title</th>
    <th>Process</th>
  </tr>
  <tr *ngFor="let gocam of gocams">
    <td>
      <a *ngIf="gocam.title" routerLink="/gocam/docs/{{gocam.gocam_id}}">{{gocam.title}}</a>
    </td>
    <td>
      <details>
        <summary>View process terms ...</summary>
        <ul>
          <li *ngFor="let termDetails of gocam.terms">
            <a routerLink="/term/{{termDetails.termid}}">{{termDetails.name}}</a>
          </li>
        </ul>
      </details>
    </td>
  </tr>
</table>
